@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-Light_V1100.eot');
    src:	url('../assets/fonts/read/Read-Light_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-Light_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-Light_V1100.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-LightItalic_V1100.eot');
    src:	url('../assets/fonts/read/Read-LightItalic_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-LightItalic_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-LightItalic_V1100.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-Italic_V1100.eot');
    src:	url('../assets/fonts/read/Read-Italic_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-Italic_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-Italic_V1100.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-Regular_V1100.eot');
    src:	url('../assets/fonts/read/Read-Regular_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-Regular_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-Regular_V1100.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-Medium_V1100.eot');
    src:	url('../assets/fonts/read/Read-Medium_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-Medium_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-Medium_V1100.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-MediumItalic_V1100.eot');
    src:	url('../assets/fonts/read/Read-MediumItalic_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-MediumItalic_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-MediumItalic_V1100.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-Bold_V1100.eot');
    src:	url('../assets/fonts/read/Read-Bold_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-Bold_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-Bold_V1100.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family:'Read';
    src:	url('../assets/fonts/read/Read-BoldItalic_V1100.eot');
    src:	url('../assets/fonts/read/Read-BoldItalic_V1100.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/read/Read-BoldItalic_V1100.woff2') format('woff2'),
    url('../assets/fonts/read/Read-BoldItalic_V1100.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}


@font-face {
    font-family:'DaciaSpirit';
    src:	url('../assets/fonts/spirit/DaciaSpirit-Light_V1200.eot');
    src:	url('../assets/fonts/spirit/DaciaSpirit-Light_V1200.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/spirit/DaciaSpirit-Light_V1200.woff2') format('woff2'),
    url('../assets/fonts/spirit/DaciaSpirit-Light_V1200.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family:'DaciaSpirit';
    src:	url('../assets/fonts/spirit/DaciaSpirit-Regular_V1200.eot');
    src:	url('../assets/fonts/spirit/DaciaSpirit-Regular_V1200.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/spirit/DaciaSpirit-Regular_V1200.woff2') format('woff2'),
    url('../assets/fonts/spirit/DaciaSpirit-Regular_V1200.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family:'DaciaSpirit';
    src:	url('../assets/fonts/spirit/DaciaSpirit-Bold_V1200.eot');
    src:	url('../assets/fonts/spirit/DaciaSpirit-Bold_V1200.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/spirit/DaciaSpirit-Bold_V1200.woff2') format('woff2'),
    url('../assets/fonts/spirit/DaciaSpirit-Bold_V1200.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family:'DaciaSpirit';
    src:	url('../assets/fonts/spirit/DaciaSpirit-Black_V1200.eot');
    src:	url('../assets/fonts/spirit/DaciaSpirit-Black_V1200.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/spirit/DaciaSpirit-Black_V1200.woff2') format('woff2'),
    url('../assets/fonts/spirit/DaciaSpirit-Black_V1200.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family:'Dacia Block';
    src:	url('../assets/fonts/daciablock/DaciaBlock-Light.woff2') format('woff2'),
    url('../assets/fonts/daciablock/DaciaBlock-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family:'Dacia Block Extended';
    src:	url('../assets/fonts/daciablock/DaciaBlockExtended-Light.woff2') format('woff2'),
    url('../assets/fonts/daciablock/DaciaBlockExtended-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family:'Dacia Block';
    src:	url('../assets/fonts/daciablock/DaciaBlock-Regular.woff2') format('woff2'),
    url('../assets/fonts/daciablock/DaciaBlock-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family:'Dacia Block Extended';
    src:	url('../assets/fonts/daciablock/DaciaBlockExtended-Regular.woff2') format('woff2'),
    url('../assets/fonts/daciablock/DaciaBlockExtended-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family:'Dacia Block';
    src:	url('../assets/fonts/daciablock/DaciaBlock-Bold.woff2') format('woff2'),
    url('../assets/fonts/daciablock/DaciaBlock-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family:'Dacia Block Extended';
    src:	url('../assets/fonts/daciablock/DaciaBlockExtended-Bold.woff2') format('woff2'),
    url('../assets/fonts/daciablock/DaciaBlockExtended-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family:'Dacia Block Variable';
    src:	url('../assets/fonts/daciablock/DaciaBlock-Variable.woff2') format('woff2'),
    url('../assets/fonts/daciablock/DaciaBlock-Variable.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

