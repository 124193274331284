@import "../../scss/utilities";

#footer {
  background-color: #eeeeee;
  padding: 2.85em 0 0;
  font-size: 0.9em;

  .footer-content {
    margin-bottom: 5.5em;
    @include pablet-portrait-and-below {
      margin-bottom: 1em;
    }
  }

  .contact {
    text-align: center;
    padding-bottom: 1em;

    @include tablet-portrait-and-below {
      text-align: center;
    }

    h3 {
      font-family: "Dacia Block Variable";
      font-size: 1.7em;
      font-weight: 900;
      margin-bottom: 1.5em;
      strong {
        color: #000000;
      }
    }

    p {
      font-size: 1.2em;
      line-height: 1.55em;
      font-weight: lighter;

      &.outro {
        max-width: 52%;
        margin: 2.4em auto 1.3em;
        @include tablet-portrait-and-below {
          margin: 1em auto 1.3em;
        }
      }
      &.service-phone {
        margin: 1.2em auto;
        font-family: "Dacia Block Variable";
        font-weight: 900;
        @include tablet-portrait-and-below {
          margin: 1em auto;
        }
      }
      &.address {
        margin: 1.5em 0;
      }
      &.company-info {
        margin: 1em auto;
      }
    }
  }

  .buttons {
    padding: 0.9em;
    // max-width: 38em;
    margin: 0 auto;

    @include pablet-portrait-and-below {
      padding: 1em 0;
      margin-bottom: 80px;
    }

    .group-btn {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;

      @include mobile-landscape-and-below {
        flex-direction: column;

        .btn-cta {
          min-width: 240px;
        }
      }
    }
  }

  .link-list {
    background-color: $black;
    padding: 1em 0;

    @include tablet-portrait-and-below {
      padding: 1em 0;
    }

    .wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include tablet-portrait-and-below {
        flex-direction: column;
      }

      ul {
        li {
          display: inline-block;
          @include tablet-portrait-and-below {
            display: block;
            line-height: 200%;
          }
          a {
            color: #fff;
            text-decoration: none;
            //font-family: RenaultLifeRegular;
            font-size: 0.875em;
            font-weight: 500;
          }
          &.no-link {
            color: #fff;
            opacity: 0.66;
            font-size: 0.875em;

            @include tablet-portrait-and-below {
              opacity: 1;
            }
          }
        }
        &.legals {
          flex: 0 1 66%;
          text-align: right;

          @include tablet-portrait-and-below {
            flex: 0 1 100%;
            text-align: left;
            border-top: 1px solid $grey;
            padding-top: 1em;
          }
          li {
            @include tablet-portrait-and-below {
              margin: 0;
            }
            margin-left: 1em;
          }
        }
        &.info {
          flex: 0 1 33%;
          @include tablet-portrait-and-below {
            flex: 0 1 100%;
            padding-bottom: 1em;
          }
          li {
            margin-right: 1em;
          }
        }
      }
    }
  }

  .footer-tool {
    display: block;
    position: relative;
    padding: 1.5em 2.3em;
    background-color: $white;

    .menu-tool {
      display: block;
      position: relative;
      list-style: none;
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      @include pablet-portrait-and-below {
        flex-wrap: wrap;
      }

      &-item {
        display: block;
        position: relative;
        text-align: center;
        width: 25%;
        display: flex;
        justify-content: center;
        @include pablet-portrait-and-below {
          width: 50%;
          margin: 0.75em 0 0;
        }
      }

      .link {
        display: block;
        padding: 0 1.2em;
        text-decoration: none;

        picture {
          display: block;
          position: relative;
          margin-bottom: 0.7em;
          img {
            display: block;
            height: 1.75em;
            margin: 0 auto;
          }
        }

        &-container {
          display: block;
          position: relative;
          font-size: 0.9em;
        }

        &-title {
          display: block;
          font-weight: bold;
          color: black;
          margin-bottom: 0.8em;
        }

        &-body {
          display: block;
          @include pablet-portrait-and-below {
            display: none;
          }
        }
      }
    }
  }

  .footer-menu {
    background-color: $black;
    color: $white;

    &-content {
      padding: 2em;
      display: flex;
      flex-wrap: nowrap;
      @include pablet-portrait-and-below {
        display: block;
      }
    }

    &-item {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      // justify-content: space-between;
      @include pablet-portrait-and-below {
        display: block;
      }

      li {
        display: flex;
        // justify-content: center;
        padding-right: 15px;
        font-size: 0.7em;
        @include pablet-portrait-and-below {
          display: block;
          margin-bottom: 0.45em;
        }
        a {
          display: block;
          text-decoration: none;
          color: $white;
        }
      }

      &.info {
        width: 57%;
        @include pablet-portrait-and-below {
          width: 100%;
        }
      }
      &.copyright {
        width: 43%;
        justify-content: flex-end;
        @include pablet-portrait-and-below {
          width: 100%;
        }
      }
    }
  }
}
