@import "../../scss/utilities";

.service-teaser {
  .teaser-img {
    img {
      width: 100%;
      max-width: 100%;
    }
    margin-bottom: 2em;
  }
}
