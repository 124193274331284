@import "../../scss/utilities";

.service-vertrage {
  padding: 3em 0 1em;
  width: calc(100% - 10em + 20px);
  margin: 0 auto;
  @include tablet-portrait-and-below {
    width: 100%;
  }

  h3 {
    font-family: $ff2;
    font-weight: lighter;
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 2.3em;

    strong {
      color: $primary-color;
    }
  }
}
