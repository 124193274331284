@import '../../scss/utilities';

.categories {
  display: block;
  &__category {
    display: block;
    margin-bottom: 4em;
    &__title {
      text-align: center;
      max-width: 570px;
      margin: 0 auto;
      margin-bottom: 2em;
      border-bottom: 2px solid $grey;
    }
    &__offers {
      width: calc(100% - 10em + 20px);
      margin: 0 auto;
      @include tablet-portrait-and-below {
        width: 100%;
      }
      .slick-list {
        height: auto!important;
      }
      .slick-next {
        right: -66px;

        &:before {
          transform: rotate(0deg);
        }

        @include tablet-portrait-and-below {
          right: -6%;
        }
      }
      .slick-prev {
        left: -66px;

        &:before {
          transform: rotate(180deg);
        }

        @include tablet-portrait-and-below {
          left: -6%;
        }
      }
      .slick-slider {
        position: relative;

        .slick-arrow {
          position: absolute;
          top: 50%;
          height: 44px;
          width: 44px;

          //text-indent: -9999px;
          background-color: $dark-grey;
          box-shadow: none;
          border: none;
          border-radius: 50%;

          @include tablet-portrait-and-below {
            transform: scale(0.66);
          }

          &.slick-disabled {
            opacity: 0.3;

            &:hover {
              cursor: not-allowed;
            }
          }

          &:focus {
            outline: 0;
          }

          &:before{
            color: $yellow;
            content: "\e60b";
            font-family: 'Renault Global', sans-serif;
            width: 21px;
            height: 21px;
            //font-size: 1.9em;
            font-size: 20px;
            padding-left: 2px;
            display: inline-block;
            font-weight: bold;
          }
        }

        .slick-dots {
          list-style: none;
          display: flex !important;
          flex-direction: row;
          justify-content: center;
          //margin-top: 1.5em;
          bottom: -36px;
          li {
            margin: 0 0.25em;

            button {

              background-color: $dark-grey;
              box-shadow: none;
              border: none;

              text-indent: -9999px;
              border-radius: 50%;
              //height: 1em;
              //width: 1em;
              height: 11px;
              width: 11px;
              padding: 0;
              margin: 0;

              &:focus {
                outline: 0;
              }
            }

            &.slick-active {
              button {
                background-color: $yellow;
              }
            }
          }
        }
      }
    }
    .is-electric {
      display: block;
      position: relative;
      padding: 0.5rem 0.8rem;
      background-color: rgb(0,172,256);
      max-width: 150px;
      color: #fff;
      text-align: center;
      margin-bottom: 1rem;

      @include tablet-portrait-and-below {
        max-width: inherit;
      }
    }

    .data-interesse {
      font-size: 1rem;
      font-weight: bold;
    }


  }
}