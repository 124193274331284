@import '../../scss/utilities';

.rleads-dropdown {
  display: block;
  position: relative;
  width: 100%;
  select {
    display: none;
  }
  &__items {
    background-color: $black;
    z-index: 1070;
    padding: 0 12px;
    &__item {
      display: flex;
      align-items: center;
      height: 39px;
      //border-top: 1px solid $warm-grey-seven;
      cursor: pointer;
      &.is-empty {
        display: none;
      }
      &__text {
        //opacity: 0.5;
        font-size: 14px;
        //font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
    &.hide-items {
      display: none;
    }
  }
  &__selected {
    height: 40px;
    background-color: $black;
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .875em;
    &:after {
      content: '';
      position: absolute;
      right: 12px;
      top: 0;
      background: url("../../assets/images/arrow.svg") no-repeat center; //00AD97
      background-size: 17px;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      max-width: 20px;
      max-height: 33px;
      margin-top: 2px;
      transform: rotate(90deg);
      transition: 0.4s;
    }
    &.is-open {
      &:after {
        transform: rotate(270deg);
      }
    }
  }
  &__selected-option {
    span {
      opacity: 1;
    }
  }
}