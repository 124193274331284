@import "../../scss/utilities";

.navigation {
  display: block;
  padding: 0.9em 1em 4.1em 1em;
  background-color: white;

  &__desktop {
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: calc(100% - 60px);
    margin: 0 auto;
    @media (min-width: 1281px) {
      display: flex;
    }
    &__item {
      cursor: pointer;
      margin: 0 0.75em;
      background-color: $primary-color;
      border-radius: 2px;

      @include tablet-landscape-and-below {
        padding-bottom: 0.5em;
      }

      &.drop-active {
        display: none;

        @include desktop-and-below {
          display: block;
        }
      }

      span {
        display: block;
        display: block;
        position: relative;
        color: $white;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 25px;
        padding-right: 25px;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        font-size: 0.97em;

        &:hover {
          color: $white-hover;
          background-color: $black;
        }
      }
    }
  }
  &__mobile {
    display: none;
    width: 100%;
    max-width: 320px;
    margin: auto;
    @media (max-width: 1280px) {
      display: block;
    }
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
