@import "../../scss/utilities";

#header {
  &.app-header {
    margin: 0em auto 0;
  }
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: white;
  position: relative;
  z-index: 11;

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.8em 2.2em;
    border-bottom: 1px solid black;
    @include mobile-landscape-and-below {
      padding: 1em 2.2em;
    }

    .dealer-name {
      background-color: #fafafa;
      align-self: center;
    }

    .renault-logo svg {
      height: 25px;

      @include mobile-landscape-and-below {
        height: 16px;
      }
    }

    .app-logo {
      width: 240px;
      display: block;
      @include tablet-landscape-and-below {
        width: 150px;
      }
      @include mobile-landscape-and-below {
        width: 100px;
      }
    }
  }

  .heading {
    padding: 2.3em 1em 1em;
    color: #000000;

    @include tablet-landscape-and-below {
      padding: 2em 1em 2em;
    }

    h2,
    h3,
    p {
      text-align: center;
    }

    h3 {
      line-height: 100%;
      font-weight: lighter;
      font-size: 1.2em;
      margin-bottom: 1.1em;
    }

    h2 {
      line-height: 100%;
      font-weight: bold;
      font-family: $ff2;
      font-size: 3.4em;
      margin-bottom: 0.45em;

      > strong {
        color: #646b52;
      }
    }

    p.intro {
      font-size: 1.1em;
      text-transform: uppercase;
      font-family: Read;
      font-weight: bold;
      margin-top: 15px;
    }
  }
}
