@import "../../scss/utilities";

.offer {
  padding: 0 10px;
  margin-bottom: 3em;

  &__slide {
    display: flex;
    flex: 0 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mobile-landscape-and-below {
      flex-direction: column;
      padding: 5px;
    }
    &:active,
    &:focus {
      outline: none;
    }
    // &.has-waranty {
    .offer-img {
      width: 100%;
      overflow: hidden;
      @include mobile-landscape-and-up {
        flex: 0 1 49%;
      }
      > div {
        position: relative;
      }
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
    // }
    &.has-warranty-3,
    &.has-plug {
      .offer-img {
        width: 100%;
        overflow: hidden;
        @include mobile-landscape-and-up {
          flex: 0 1 49%;
        }
        > div {
          position: relative;
          &:before {
            content: "";
            //height: 83px;
            height: 27%;
            width: 27%;
            margin-bottom: 2%;
            //width: 127px;
            position: absolute;
            bottom: 0;
            right: 0;
            background-image: url("../../assets/images/3_warranty_logo.png");
            background-size: contain;
            background-repeat: no-repeat;
            transform: scale(0.8);
            z-index: 99;
          }
        }
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }
    &.has-warranty-5,
    &.has-plug {
      .offer-img {
        width: 100%;
        overflow: hidden;
        @include mobile-landscape-and-up {
          flex: 0 1 49%;
        }
        > div {
          position: relative;
          &:before {
            content: "";
            //height: 53px;
            //width: 127px;
            height: 27%;
            width: 27%;
            margin-bottom: 2%;
            position: absolute;
            bottom: 0;
            right: 0;
            background-image: url("../../assets/images/5_warranty_logo.png");
            background-size: contain;
            background-repeat: no-repeat;
            transform: scale(0.8);
            z-index: 99;
          }
        }
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }
    &.has-plug {
      .offer-img {
        > div {
          &:before {
            background-image: url("../../assets/images/Stecker_ZE.png");
            background-size: contain;
            background-repeat: no-repeat;
            width: 68px;
          }
        }
      }
    }
    &.has-ze {
      .offer-img {
        width: 100%;
        overflow: hidden;
        @include mobile-landscape-and-up {
          flex: 0 1 49%;
        }
        > div {
          position: relative;
          &:before {
            content: "";
            height: 53px;
            width: 117px;
            position: absolute;
            bottom: 0;
            right: -15px;
            background-image: url("../../assets/images/ZE.png");
            background-size: contain;
            transform: scale(0.8);
            z-index: 99;
          }
        }
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }
    }
    .offer-text {
      flex: 0 1 49%;
      @include mobile-landscape-and-below {
        flex: 0 1 100%;
        padding: 0 1.5em;
        margin-top: 1.5em;
      }
      @include mobile-portrait-and-below {
        padding: 0 1em;
      }
      h4 {
        line-height: 90%;
        margin-bottom: 0.65em;
        font-size: 1em;
        font-weight: 200;
        font-family: Read;
      }
      h5 {
        font-family: "Dacia Block";
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0.55em;
        font-size: 1.6em;

        &.subheadline {
          font-size: 1.16em;
          line-height: 1.1em;
          margin-bottom: 1em;

          sup {
            top: -0.75em;
          }
        }

        &.teaser {
          font-weight: 200;
          font-family: Read;
          font-size: 0.84em;
          margin-bottom: 1.1em;
          line-height: 1.1em;
        }

        strong {
          text-transform: uppercase;
        }
      }
      .description {
        margin-bottom: 2em;
      }

      .modelName {
        font-size: 1em;
        font-family: "Dacia Block";
      }

      ul {
        padding: 0;
        margin: 1em 0 1.8em 20px;
        color: $secondary-text-color;
        li {
          line-height: 1.1em;
          margin-bottom: 0.4em;
          font-size: 0.84em;
          span {
            left: -6px;
            position: relative;
          }
        }
      }
      .price-rate-bold {
        font-weight: bold;
      }
      .price-rate-small {
        font-size: 0.84em;
      }
      p {
        line-height: 120%;
        font-weight: 300;
        .price {
          font-weight: bold;
          font-size: 1.3em;
        }

        strong {
          color: $primary-text-color;
        }
      }
      .separator {
        display: block;
        height: 1px;
        margin-bottom: 1.5em;
      }
      span.offer-expand {
        color: $black;
        display: inline-block;
        border-bottom: 2px solid $black;
        text-decoration: none;
        //font-family: RenaultLifeBold;
        font-weight: bold;
        margin-top: 1.5em;
        line-height: 90%;
        position: relative;
        cursor: pointer;
        &:active,
        &:focus {
          outline: none;
        }
        // &:after {
        //   background-color: $black;
        //   -webkit-mask-image: url('../../assets/images/arrow.svg');
        //   mask-image: url('../../assets/images/arrow.svg');
        //   position: absolute;
        //   top: 2px;
        //   left: calc(100% + 1em);
        //   transform: rotate(90deg);
        //   display: inline-block;
        //   content: '';
        //   height: 10px;
        //   width: 10px;
        //   transition: all .3s;
        // }
        // &.is-open {
        //   &:after {
        //     background-color: $black;
        //     -webkit-mask-image: url('../../assets/images/arrow.svg');
        //     mask-image: url('../../assets/images/arrow.svg');
        //     position: absolute;
        //     top: 2px;
        //     left: calc(100% + 1em);
        //     transform: rotate(270deg);
        //     display: inline-block;
        //     content: '';
        //     height: 10px;
        //     width: 10px;
        //   }
        // }

        &.btn {
          display: block;
          position: relative;
          background: #000000;
          padding: 1.2em 1em;
          border: none;
          color: #fff;
          border-radius: 50px;
          text-align: center;
          max-width: 260px;
          margin: 0 auto;
          font-size: 0.8em;
          line-height: 0.8em;
        }
      }
    }

    span.offer-expand-energie {
      color: $black;
      display: inline-block;
      border-bottom: 2px solid $black;
      text-decoration: none;
      text-transform: uppercase;
      //font-family: RenaultLifeRegular;
      font-weight: 400;
      line-height: 90%;
      position: relative;
      cursor: pointer;
      &:active,
      &:focus {
        outline: none;
      }
    }

    .offer-expandable {
      flex: 0 1 100%;
      display: none;
      overflow: hidden;
      .expandable-holder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include tablet-portrait-and-below {
          padding: 0 1.5em;
          overflow: initial;
          flex-direction: column;
        }
        .expand-list {
          flex: 0 1 49%;
          ul {
            padding: 0;
            margin: 1em 0 3.6em 20px;
            li {
              line-height: 120%;
              margin-bottom: 0.5em;
              //font-family: RenaultLifeLight;
              font-weight: 300;
              span {
                left: -6px;
                position: relative;
              }
            }
          }
          p {
            //font-family: RenaultLifeLight;
            font-weight: 300;
            line-height: 120%;
            &.micro {
              font-size: 0.75em;
              margin-top: 1em;
              a {
                color: #000;
              }
            }
          }
          img {
            width: 100%;
            margin-top: 1em;
          }
        }
        .expand-images {
          width: 49%;
          margin-top: 10px;
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          justify-content: space-between;
          @include tablet-portrait-and-below {
            width: 100%;
          }
          @include mobile-landscape-and-below {
            flex-direction: column;
          }
          img {
            width: calc(100% - 10px);
            height: auto;
            @include mobile-landscape-and-below {
              width: 100%;
              margin: 0 0 10px 0;
            }
            &:first-child {
              margin-right: 20px;
            }
          }
          p {
            //font-family: RenaultLifeLight;
            font-weight: 300;
            line-height: 120%;
            &.micro {
              font-size: 0.75em;
              margin-top: 1em;
              a {
                color: #000;
              }
            }
          }
        }
      }
      figcaption {
        padding: 5px 10px 10px 0;
        line-height: 120%;
        font-size: 10px;
      }

      &.sm {
        flex: 0 1 49%;

        @include tablet-portrait-and-below {
          width: 100%;
        }

        .expandable-holder {
          flex-direction: column;
          .expand-list {
            flex: 0 1 100%;
          }
          .expand-images {
            width: 100%;
          }
        }
      }
    }
    .offer-expandable-energie {
      flex: 0 1 100%;
      display: none;
      overflow: hidden;
      .expandable-holder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include tablet-portrait-and-below {
          padding: 0 1.5em;
          overflow: initial;
          flex-direction: column-reverse;
        }
        .expand-list {
          flex: 0 1 49%;
          ul {
            padding: 0;
            margin: 1em 0 1em 20px;
            li {
              line-height: 120%;
              margin-bottom: 0.5em;
              //font-family: RenaultLifeLight;
              font-weight: 300;
              span {
                left: -6px;
                position: relative;
              }
            }
          }
          p {
            //font-family: RenaultLifeLight;
            font-weight: 300;
            line-height: 120%;
            &.micro {
              font-size: 0.75em;
              margin-top: 1em;
              line-height: 1.6em;

              a {
                color: #000;
              }
            }
          }
          img {
            width: 100%;
            margin-top: 1em;
          }
        }
        .expand-images {
          width: 49%;
          margin-top: 10px;
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: space-between;
          @include tablet-portrait-and-below {
            width: 100%;
          }
          @include mobile-landscape-and-below {
            flex-direction: column-reverse;
          }
          img {
            width: calc(100% - 10px);
            height: auto;
            @include mobile-landscape-and-below {
              width: 100%;
              margin: 0 0 10px 0;
            }
            &:first-child {
              margin-right: 20px;
            }
          }
          p {
            //font-family: RenaultLifeLight;
            font-weight: 300;
            line-height: 120%;
            &.micro {
              font-size: 0.75em;
              margin-top: 1em;
              line-height: 1.6em;
              font-weight: normal;

              a {
                color: #000;
              }
            }
          }
        }
      }
      figcaption {
        padding: 5px 10px 10px 0;
        line-height: 120%;
        font-size: 10px;
      }
    }
    .offer-legals {
      flex: 0 1 100%;
      margin-top: 0.75em;

      @include tablet-portrait-and-below {
        padding: 0 1.5em;
        overflow: initial;
      }
      p {
        font-weight: bold;
        line-height: 120%;
        color: #777777;
        &.mini {
          font-size: 0.75em;
          //   line-height: 1.6em;
        }
        &.micro {
          font-size: 0.75em;
          margin-top: 0.25em;
          //   line-height: 1.6em;
          a {
            color: #000;
          }
        }
      }
    }
    .offer-empty-block {
      flex: 0 1 49%;
      overflow: hidden;
      display: flex;
      @include tablet-portrait-and-below {
        display: none;
      }
    }
  }
  @include mobile-landscape-and-below {
    padding: 0;
  }
}
