$rdag-icon-font-path: "../assets/icons/rdag-icons/" !default;
$global-icon-font-path: "../assets/icons/global-icons/" !default;

@font-face {
  font-family: 'renault-global';
  src:url('#{$global-icon-font-path}renault-global.eot?-i6rgud');
  src:url('#{$global-icon-font-path}renault-global.eot?#iefix-i6rgud') format('embedded-opentype'),
  url('#{$global-icon-font-path}renault-global.woff?-i6rgud') format('woff'),
  url('#{$global-icon-font-path}renault-global.ttf?-i6rgud') format('truetype'),
  url('#{$global-icon-font-path}renault-global.svg?-i6rgud#renault-global') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'rdag-icon';
  src:url('#{$rdag-icon-font-path}rdag-icon.eot?-i6rgud');
  src:url('#{$rdag-icon-font-path}rdag-icon.eot?#iefix-i6rgud') format('embedded-opentype'),
  url('#{$rdag-icon-font-path}rdag-icon.woff?-i6rgud') format('woff'),
  url('#{$rdag-icon-font-path}rdag-icon.ttf?-i6rgud') format('truetype'),
  url('#{$rdag-icon-font-path}rdag-icon.svg?-i6rgud#rdag-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.renault-global {
  font-family: 'renault-global';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rdag-icon {
  font-family: 'rdag-icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rdag-2x {
  font-size: 2em;
}
.rdag-3x {
  font-size: 3em;
}
.rdag-4x {
  font-size: 4em;
}

.icon-user:before {
  content: "\e600";
}

.icon-home:before {
  content: "\e601";
}

.icon-search:before {
  content: "\e602";
}

.icon-calendar:before {
  content: "\e603";
}

.icon-basket:before {
  content: "\e604";
}

.icon-picture:before {
  content: "\e605";
}

.icon-video:before {
  content: "\e606";
}

.icon-link:before {
  content: "\e607";
}

.icon-mail:before {
  content: "\e608";
}

.icon-favorite:before {
  content: "\e609";
}

.icon-vehicle:before {
  content: "\e60a";
}

.icon-lcv:before {
  content: "\e60b";
}

.icon-retail:before {
  content: "\e60c";
}

.icon-service:before {
  content: "\e60d";
}

.icon-offer:before {
  content: "\e60e";
}

.icon-testdrive:before {
  content: "\e60f";
}

.icon-configure:before {
  content: "\e610";
}

.icon-broschure:before {
  content: "\e611";
}

.icon-callcenter:before {
  content: "\e612";
}

.icon-livechat:before {
  content: "\e613";
}

.icon-desktop:before {
  content: "\e614";
}

.icon-laptop:before {
  content: "\e615";
}

.icon-tablet:before {
  content: "\e616";
}

.icon-phone:before {
  content: "\e617";
}

.icon-turn:before {
  content: "\e618";
}

.icon-facebook:before {
  content: "\e619";
}

.icon-twitter:before {
  content: "\e61a";
}

.icon-linkedin:before {
  content: "\e61b";
}

.icon-google:before {
  content: "\e61c";
}

.icon-youtube:before {
  content: "\e61d";
}

.icon-arrow-big-left:before {
  content: "\e61e";
}

.icon-arrow-big-right:before {
  content: "\e61f";
}

.icon-arrow-yellow-up .path1:before {
  content: "\e620";
  color: rgb(255, 204, 51);
}

.icon-arrow-yellow-up .path2:before {
  content: "\e621";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-arrow-yellow-down .path1:before {
  content: "\e622";
  color: rgb(255, 204, 51);
}

.icon-arrow-yellow-down .path2:before {
  content: "\e623";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-arrow-yellow-left .path1:before {
  content: "\e624";
  color: rgb(255, 204, 51);
}

.icon-arrow-yellow-left .path2:before {
  content: "\e625";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-arrow-yellow-right .path1:before {
  content: "\e626";
  color: rgb(255, 204, 51);
}

.icon-arrow-yellow-right .path2:before {
  content: "\e627";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-video-play:before {
  content: "\e628";
}

.icon-video-pause:before {
  content: "\e629";
}