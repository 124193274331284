@import "fonts";

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: $ff, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-text-color;
  font-size: 16px;
  @include tablet-portrait-and-below {
    font-size: 14px;
  }

  * {
    box-sizing: border-box;
    &::selection {
      background: $primary-color;
      color: $white;
    }
  }
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5  {
  font-size: 1em;
}

p {
  font-size: 1em;
}

h1,
h2 {
  font-family: $ff2;
}

sub,
sup {
  font-size: 0.6em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.45em;
}

sub {
  bottom: -0.5em;
}

a {
  color: $black-two;

  &:active,
  &:focus {
    outline: none;
  }
}

.hero {
  margin-top: 0;
  margin-bottom: 2.5em;
  overflow: hidden;
  img {
    width: 100%;
    max-width: 100%;
  }
}

.list-item-plus {
  list-style: none;
  position: relative;
  padding-left: 13px;

  &:before {
    content: "+";
    font-size: 14px;
    margin-right: 5px;
    position: absolute;
    height: 100%;
    left: 0;
  }
}

.price-rate {
  text-align: right;
  border-top: 1px solid $black;
  border-bottom: none;
  padding: 0.8em 2em 0;
  margin-bottom: 0;

  sup {
    top: -0.6em;
  }

  .sub-inclusive {
    font-weight: 600;
    font-size: 1.5em;
    sup {
      font-size: 0.5em !important;
      top: -0.7em !important;
    }
  }

  .micro {
    font-size: 0.75em;
    display: block;

    a {
      color: $black;
    }
  }
}

.offer-text {
  li {
    font-weight: 300;
  }
}
.data-inclusive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $black;
  font-weight: 300;

  .price {
    font-weight: bold;
    font-size: 1.5em;
  }

  &.small-content {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  ul {
    margin-left: 3px !important;
    flex: 1;

    li {
      font-weight: 600;
      list-style: none;
    }
  }

  span:not(.prefix):not(.monthly-price) {
    padding-left: 10px;
    flex: 1;
    text-align: right;

    &.small-size:not(.prefix) {
      font-size: 1em !important;
    }

    .prefix {
      font-size: 15px;
      font-weight: 300;

      &.top {
        display: block;
      }
      + sup {
        font-size: 0.5em !important;
        top: -0.7em !important;
      }
    }
  }
  .monthly-price {
    font-size: 1.5em;
    font-weight: bold;
  }
  .multi-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;

    span {
      &:first-of-type {
        margin-bottom: 1.5em;
      }
    }
  }
}

.data-descr {
  line-height: 120%;
  padding-top: 5px;
  font-weight: 300;
}

#wrapper {
  max-width: 100vw;
  overflow-x: hidden;

  .wrap {
    max-width: 1140px;
    width: 90%;
    margin: 0 auto 0;
  }
}

.btn-cta {
  display: block;
  position: relative;
  background: #646b52;
  padding: 8px 25px 6px;
  border: none;
  color: $white;
  border-radius: 2px;
  text-align: center;
  max-width: 247px;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: bold;
  min-height: 3em;
  font-size: 0.8em;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    color: $white;
    background: $black;
  }
}

.mb-4 {
  margin-bottom: 4.3em;
}

.micro {
  font-size: 0.75em;
  margin-top: 1em;
  line-height: 1.6em;
  font-weight: normal;

  a {
    color: #000;
  }
}
