$ff: "Read", sans-serif;
$ff2: "Dacia Block", sans-serif;
$font-size-base: 18px;
$font-size-base-mobile: 16px;
$line-height-base: 1.3em;
$prod: true !default;
$imgBase: "..";
//$imgBase: 'https://forms.renault.de';

//@import "variables";
@if $prod == false {
  $imgBase: "";
}

$primary-color-dark: #0d0f17;
$primary-color: #646b52;
$secondary-color: #333333;

$primary-text-color: #333333;
$secondary-text-color: #5d5d5d; // #a1adb7;// #526275;

$black: #000;
$white: #fff;
$yellow: #f8c747;
$red: #c60c30;
$clio-orange: #e67933;

$yellow-hover: #ffde00;

$yellow-desktop: #fecb00;
$yellow-desktop-hover: #ffde00;

$grey1: #333;
$grey1-hover: #555;

$grey2: #666;
$grey2-hover: lighten($grey2, 10%);

$grey3: #999;
$grey3-hover: lighten($grey3, 10%);

$grey4: #ccc;
$grey4-hover: #e1e1e1;

$grey5: #eee;
$grey5-hover: #efefef;

$grey6: #f4f2f1;

$brownish-grey: #5d5d5d;
$brown-grey-3: #7e7e7e;

$white: #fff;
$white-hover: #e6e6e6;

$error: #c3261f;
$success: #59a618;

$black-two: #353535;
$black-three: #333333;
$warm-grey-four: #ececec;
$warm-grey: #828282;
$warm-grey-two: #9b9b9b;
$warm-grey-three: #979797;
$warm-grey-five: #e2e2e2;
$warm-grey-six: #919191;
$warm-grey-seven: #dddddd;
$brown-grey: #909090;

$dark-sky-blue: #4a90e2;

$grey: #cccccc;
$light-grey: #ececec;
$dark-grey: #333333;
