@import "../../scss/utilities";

.service-insurance {
  padding: 0;
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 10em + 20px);
  margin: 0 auto 4.5em;

  @include tablet-portrait-and-below {
    flex-direction: column;
    padding: 0;
  }

  h3 {
    font-family: $ff2;
    font-size: 1.6em;
    font-weight: bold;
    line-height: 1.3em;
    text-transform: uppercase;

    strong {
      color: $primary-color;
    }
  }

  h4 {
    font-size: 0.75em;
    font-weight: bold;
    font-family: Read;
  }

  h5 {
    // font-family: $ff2;
    line-height: 110%;
    margin: 0 0 0.25em;
    font-weight: bold;
    font-size: 0.8em;
    color: $secondary-color;

    span {
      display: block;
      font-weight: 300;
      margin: 0.5em 0 0;
    }
  }

  p {
    // font-family: $ff2;
    font-weight: lighter;
    font-size: 0.9em;
    color: $secondary-color;
  }

  ul {
    padding: 0;
    margin: 0.5em 0 0 20px;
  }

  li {
    line-height: 120%;
    padding: 0.5em 0;
  }

  .heading {
    width: 100%;
    overflow: hidden;
    @include tablet-landscape-and-below {
      padding: 0 1em 1em;
    }
    @include mobile-landscape-and-up {
      flex: 0 1 49%;
    }

    h3 strong {
      font-family: $ff2;
      font-weight: 500;
    }

    h4 {
      margin-bottom: -5px;
      font-family: "Read", sans-serif;
    }

    > div {
      position: relative;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .insurances {
    flex: 0 1 49%;

    display: block;
    // padding: 1em 8em;
    @include tablet-portrait-and-below {
      padding: 0.7em 1em;
    }
    // color: $primary-color;

    &__items {
      flex: 1 1;
      max-width: 100%;
      margin: 0;
      margin-bottom: 3.1em;

      @include tablet-portrait-and-below {
        max-width: 100%;
        margin: 0;
      }
    }

    &__item {
      // text-align: justify;
      margin: 0 0 1.85em;
    }

    &__offer {
      flex: 1;
      max-width: 50%;
      margin: 0 0 0 2em;
      background-color: $yellow-desktop;
      padding: 1em;

      &--headline {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $white;

        div {
          display: flex;
          align-items: flex-end;
          font-weight: bold;
          margin: -1em 0 0.75em;
        }
      }

      .price {
        font-size: 3em;
        line-height: 1;

        sup {
          top: -1em;
          font-size: 40%;
        }
      }

      @include tablet-portrait-and-below {
        max-width: 100%;
        margin: 0;
      }
    }

    &__footnodes {
      font-weight: 300;
      line-height: 130%;
      text-align: justify;
      margin: 2em 0 0;
    }

    @include tablet-portrait-and-below {
      flex-direction: column;
    }
  }
}
